import React from 'react';
import NumberFormat from 'react-number-format';
import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import { Helpers } from '../../../../utils/helpers';
import { PagingControls, LoadingContainer } from '../../../../components/ui';
import useBillSearch from './useBillSearch';
import styles from './BillSearch.module.css';

export const BillSearch = () => {
  const manager = useBillSearch();

  const customerTransactions =
    manager.billSearchCustomers.find((cust) => cust.id === manager.searchParameters.customerId)
      ?.transactions ?? [];

  return (
    <>
      <Modal
        show={manager.showDetailsModal}
        keyboard={true}
        backdrop="static"
        fullscreen={false}
        size="md"
        centered={true}
        onHide={manager.closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Bill Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <LoadingContainer isLoading={manager.modalLoading}>
                {manager.apiDetailError.trim() !== '' && (
                  <div className="alert-danger mb-1 p-2">{manager.apiDetailError}</div>
                )}
                {manager.billDetails && (
                  <>
                    <div className={styles.detailHeader}>
                      <h3>{manager.billDetails.customerName}</h3>
                      <div>{manager.billDetails.transactionName}</div>
                      <div className={styles.paidContainer}>
                        {manager.billDetails.isPaid && (
                          <span className={styles.paidBadge}>PAID</span>
                        )}
                        {!manager.billDetails.isPaid && (
                          <span className={styles.unpaidBadge}>NOT PAID</span>
                        )}
                      </div>
                    </div>
                    <table className="table table-striped">
                      <tbody>
                        {manager.billDetails.data.map((kvp) => {
                          return (
                            <tr key={kvp.rowId}>
                              <th scope="row">{kvp.key}</th>
                              {kvp.isAmountDue && kvp.decimalValue !== null && (
                                <td>{Helpers.formatCurrency(kvp.decimalValue)}</td>
                              )}
                              {(!kvp.isAmountDue || kvp.decimalValue === null) && (
                                <td>{kvp.value}</td>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                )}
              </LoadingContainer>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={manager.showFieldsModal}
        keyboard={true}
        backdrop="static"
        fullscreen={false}
        size="lg"
        centered={true}
        onHide={manager.closeFieldsModal}>
        <Modal.Header closeButton>
          <Modal.Title>Available Search Fields</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {manager.transactionFields.map((field) => {
              return (
                <div key={field.rowId} className="col-6 pb-1">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id={`fieldSelector${field.rowId}`}
                      checked={field.visible}
                      onChange={() => {
                        manager.toggleField(field.rowId);
                      }}
                    />
                    <label className="form-check-label" htmlFor={`fieldSelector${field.rowId}`}>
                      {field.label}
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
        </Modal.Body>
      </Modal>

      <div className="titleBar">
        <h1 className="pageTitle">Bill Search</h1>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-4">
            <div className="payPalSearchContainer border">
              <div className="g-3 heading">
                <div className="col-auto">
                  {manager.billSearchCustomers.length === 1 ? (
                    <h2 className="h6">Select a transaction and provide search criteria:</h2>
                  ) : (
                    <h2 className="h6">
                      Select a customer, transaction, and provide search criteria:
                    </h2>
                  )}
                </div>
              </div>
              <div className="g-3 w-100">
                <div className="form-group">
                  <label htmlFor="customerId" className="d-block">
                    Customer{' '}
                    {manager.billSearchCustomers.length !== 1 && <span className="req">*</span>}
                  </label>
                  {manager.billSearchCustomers.length === 1 && (
                    <div className={styles.researchCustomerName}>
                      {manager.billSearchCustomers[0].name}
                    </div>
                  )}
                  {manager.billSearchCustomers.length > 1 && (
                    <>
                      <select
                        id="customer"
                        className="form-select"
                        aria-label="Customer selection"
                        value={manager.searchParameters.customerId ?? ''}
                        onChange={(e) =>
                          manager.updateSearchParams(
                            'customerId',
                            Helpers.tryParseInt(e.target.value, null)
                          )
                        }>
                        <option>- Select -</option>
                        {manager.billSearchCustomers.map((customer) => {
                          return (
                            <option key={customer.id} value={customer.id}>
                              {customer.name}
                            </option>
                          );
                        })}
                      </select>
                      {manager.errors.customerId && (
                        <div className="mt-1 alert-danger p-2">Customer selection required.</div>
                      )}
                    </>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="transaction">Transaction</label>
                  <select
                    id="transaction"
                    className="form-select"
                    aria-label="Transaction selection"
                    disabled={!manager.searchParameters.customerId}
                    value={manager.searchParameters.transactionId ?? ''}
                    onChange={(e) =>
                      manager.updateSearchParams(
                        'transactionId',
                        Helpers.tryParseInt(e.target.value, null)
                      )
                    }>
                    {customerTransactions.length > 1 && (
                      <option value="0">&ndash; All Transactions &ndash;</option>
                    )}
                    {customerTransactions.map((transaction) => {
                      return (
                        <option key={transaction.id} value={transaction.id}>
                          {transaction.name}
                        </option>
                      );
                    })}
                  </select>
                  {manager.errors.transactionId && (
                    <div className="mt-1 alert-danger p-2">Transaction selection required.</div>
                  )}
                </div>

                <div className="row">
                  <div className="col-12">
                    <LoadingContainer spinnerSize="sm" isLoading={manager.loadingFields}>
                      {manager.searchParameters.transactionId !== null &&
                        manager.transactionFields.length === 0 && (
                          <div>No searchable fields found.</div>
                        )}
                      {manager.transactionFields.length > 0 && (
                        <>
                          {manager.transactionFields.length > manager.defaultFieldsVisible && (
                            <div className="mt-1 mb-1">
                              <a
                                href="#"
                                className="btn btn-primary btn-sm"
                                onClick={(e) => {
                                  e.preventDefault();
                                  manager.setShowFieldsModal(true);
                                }}>
                                <FontAwesomeIcon className="me-2" icon={faList} />
                                Select search fields ({manager.getFieldCounts()})
                              </a>
                            </div>
                          )}

                          <div>
                            {manager.transactionFields.map((field) => {
                              if (field.isAmountDue) {
                                return (
                                  <div
                                    key={field.rowId}
                                    className={`form-group ${!field.visible ? 'd-none' : ''}`}>
                                    <label htmlFor={`field${field.rowId}`} title={field.columnName}>
                                      {field.label}:
                                    </label>
                                    <NumberFormat
                                      id={`field${field.rowId}`}
                                      className="form-control"
                                      prefix="$"
                                      allowEmptyFormatting={true}
                                      allowLeadingZeros={false}
                                      allowNegative={true}
                                      decimalScale={2}
                                      fixedDecimalScale={false}
                                      thousandSeparator={true}
                                      value={field.value}
                                      onKeyDown={(e) => manager.handleSearchKeyDown(e)}
                                      onValueChange={(values) =>
                                        manager.updateFieldValue(
                                          field.rowId,
                                          values.floatValue?.toString() ?? ''
                                        )
                                      }
                                    />
                                    {!Helpers.isNullOrWhitespace(field.value) && (
                                      <div className="ms-1">
                                        <label
                                          htmlFor={`fieldFuzzy${field.rowId}`}
                                          className={`form-label ${styles.fuzzyLabel}`}>
                                          <span className="d-inlineblock me-2">
                                            Fuzzy Search ({field.fuzziness}%)
                                          </span>
                                        </label>
                                        <div>
                                          <input
                                            type="range"
                                            id={`fieldFuzzy${field.rowId}`}
                                            className="form-range"
                                            min="0"
                                            max="100"
                                            value={field.fuzziness}
                                            onChange={(e) =>
                                              manager.updateFuzzinessValue(
                                                field.rowId,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                        {field.fuzziness > 0 && (
                                          <div className={styles.fuzzyAmounts}>
                                            {manager.calculateFuzziness(
                                              field.value,
                                              field.fuzziness
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                );
                              } else {
                                return (
                                  <div
                                    key={field.rowId}
                                    className={`form-group ${!field.visible ? 'd-none' : ''}`}>
                                    <label htmlFor={`field${field.rowId}`}>
                                      {field.columnName}:
                                    </label>
                                    <input
                                      type="text"
                                      id={`field${field.rowId}`}
                                      className="form-control"
                                      maxLength={100}
                                      value={field.value}
                                      onKeyDown={(e) => manager.handleSearchKeyDown(e)}
                                      onChange={(e) =>
                                        manager.updateFieldValue(field.rowId, e.target.value)
                                      }
                                    />
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </>
                      )}
                      {manager.errors.fieldData && (
                        <div className="mt-1 alert-danger p-2">
                          You must enter search criteria for at least one field.
                        </div>
                      )}
                    </LoadingContainer>
                  </div>
                </div>

                <div className="mt-3 d-flex justify-content-between">
                  <button className="searchSubmit btn btn-primary" onClick={manager.resetSearch}>
                    Reset
                  </button>
                  <button className="searchSubmit btn btn-primary" onClick={manager.runSearch}>
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-8">
            {manager.apiError.trim() !== '' && (
              <div className="alert-danger mb-1 p-2">{manager.apiError}</div>
            )}
            <div className="card" ref={manager.scrollToRef}>
              <div className="card-body">
                {manager.loading && <Spinner animation="border" />}
                {!manager.loading && (
                  <div className="table-responsive">
                    {!manager.pagedResult && (
                      <>
                        <h2>Search for Bills</h2>
                        <div>Use the search form to find bills.</div>
                      </>
                    )}
                    {manager.pagedResult && manager.pagedResult.results.length === 0 && (
                      <>
                        <h2>No Results for Your Search</h2>
                        <div>Please update your search criteria and try again.</div>
                      </>
                    )}
                    {manager.pagedResult?.results.length > 0 && (
                      <>
                        <div className={styles.resultCount}>
                          {manager.pagedResult.resultCount} result
                          {manager.pagedResult.resultCount === 1 ? '' : 's'} found.
                        </div>
                        <h2>Results</h2>
                        <table className="table table-striped table-hover">
                          <thead>
                            <tr>
                              <th scope="col"></th>
                              <th scope="col">Transaction</th>
                              <th scope="col">{manager.pagedResult.fileKeyHeader}</th>
                              <th scope="col">Name</th>
                              <th scope="col">Amount Due</th>
                              <th scope="col">Paid</th>
                            </tr>
                          </thead>
                          <tbody>
                            {manager.pagedResult?.results.map((result) => {
                              return (
                                <tr
                                  key={result.tRowId}
                                  className={
                                    manager.isRowViewed(result.tRowId) ? styles.rowViewed : ''
                                  }>
                                  <td>
                                    <a
                                      href="#!"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        manager.viewDetails(result.tRowId);
                                      }}>
                                      View
                                    </a>
                                  </td>
                                  <td>{result.transactionName}</td>
                                  <td>
                                    {result.fileKey.trim() !== '' ? result.fileKey : <>&mdash;</>}
                                    {manager.pagedResult.fileKeyCount > 1 && (
                                      <div className={styles.fileKeyName}>{result.fileKeyName}</div>
                                    )}
                                  </td>
                                  <td>{result.name.trim() !== '' ? result.name : <>&mdash;</>}</td>
                                  <td>{Helpers.formatCurrency(result.amountDue)}</td>
                                  <td>
                                    {result.isPaid && (
                                      <span className={styles.paidBadge}>PAID</span>
                                    )}
                                    {!result.isPaid && (
                                      <span className={styles.unpaidBadge}>NOT PAID</span>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        <div>
                          <PagingControls
                            page={manager.pagedResult.currentPage}
                            pageCount={manager.pagedResult.pageCount}
                            changePageCallback={manager.changePage}
                          />
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
