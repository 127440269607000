import { useState, useEffect, useCallback } from 'react';
import { Api } from '../../../../utils/helpers';

const usePayPal = ({ customerParameters }) => {
  const [loading, setLoading] = useState(true);
  const [loadingCustomer, setLoadingCustomer] = useState(true);
  const [customerRecord, setCustomerRecord] = useState(null);
  const [hasPayPalIds, setPayPalStatusFlag] = useState(false);
  const defaultSelectValue = {
    value: '',
    label: '- Select Managed Account -'
  };
  const [currentSelectValue, setSelectValue] = useState(defaultSelectValue);
  const [addManagedAccountFlag, setAddManagedAccountFlag] = useState(false);
  const [payPalIdSelection, setPayPalIdSelection] = useState(-1);
  const [showProcessView, setShowProcessViewFlag] = useState(false);
  const [governmentFormStatus, getGovernmentFormStatus] = useState(false);
  const [governmentFormData, setGovernmentFormData] = useState('');
  const [governmentErrorForm, setGovernmentErrorForm] = useState('');
  const [governmentSubmissionError, setGovernmentSubmissionError] = useState('');
  const [corporationFormStatus, getCorporationFormStatus] = useState(false);
  const [corporationFormData, setCorporationFormData] = useState('');
  const [corporationErrorForm, setCorporationErrorForm] = useState('');
  const [corporationSubmissionError, setCorporationSubmissionError] = useState('');
  const [nonprofitFormStatus, getNonprofitFormStatus] = useState(false);
  const [nonprofitFormData, setNonprofitFormData] = useState('');
  const [nonprofitErrorForm, setNonprofitErrorForm] = useState('');
  const [nonprofitSubmissionError, setNonprofitSubmissionError] = useState('');
  const [proprietorshipFormStatus, getProprietorshipFormStatus] = useState(false);
  const [proprietorshipFormData, setProprietorshipFormData] = useState('');
  const [proprietorshipErrorForm, setProprietorshipErrorForm] = useState('');
  const [proprietorshipSubmissionError, setProprietorshipSubmissionError] = useState('');

  const resetForms = async () => {
    setPayPalIdSelection(-1);
    setAddManagedAccountFlag(false);
    setShowProcessViewFlag(false);
    setSelectValue(defaultSelectValue);
  };

  const getCustomerRecord = useCallback(async () => {
    setLoading(true);
    setLoadingCustomer(true);
    setCustomerRecord(null);
    setGovernmentErrorForm('');
    resetForms();

    if (customerParameters.brandId <= 0 || customerParameters.customerId <= 0) {
      setLoading(false);
      setLoadingCustomer(false);
      return false;
    }

    const apiResponse = await Api.get(
      `/paypal/Onboarding/Customer/${customerParameters.brandId}/${customerParameters.customerId}`
    );

    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred');
      setLoading(false);
      setLoadingCustomer(false);
      return;
    }

    let response = apiResponse.response;

    setCustomerRecord(response);
    setPayPalStatusFlag(Boolean(response.hasPayPalIds));
    setLoadingCustomer(false);
    setLoading(false);
  }, [customerParameters]);

  useEffect(() => {
    getCustomerRecord();
  }, [getCustomerRecord]);

  const addManagedAccount = () => {
    resetForms();
    setAddManagedAccountFlag(true);
  };

  const updateSelect = (option) => {
    resetForms();
    setSelectValue({ value: option.value, label: option.label });
    setPayPalIdSelection(option.value);
  };

  useEffect(() => {
    if (payPalIdSelection !== -1) {
      setShowProcessViewFlag(true);
    }
  }, [payPalIdSelection]);

  const governmentFormStatusCallback = (formStatus, componentData) => {
    getGovernmentFormStatus(formStatus);
    setGovernmentFormData(componentData);
  };

  const submitGovernmentForm = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (customerRecord.brandId <= 0 || customerRecord.id <= 0) {
      setLoading(false);
      return false;
    }

    const postData = JSON.stringify(governmentFormData);
    const apiResponse = await Api.post(
      `/paypal/Onboarding/ManagedAccount/Municipality/${customerRecord.brandId}/${customerRecord.id}`,
      postData
    );

    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred');
      setLoading(false);
      return;
    }

    let response = apiResponse.response;

    if (!response.accountId) {
      setGovernmentErrorForm(governmentFormData);
      setGovernmentSubmissionError(response.formattedErrors);
    } else {
      getCustomerRecord();
    }

    setLoading(false);
  };

  const submitCorporationForm = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (customerRecord.brandId <= 0 || customerRecord.id <= 0) {
      setLoading(false);
      return false;
    }

    const postData = JSON.stringify(corporationFormData);
    const apiResponse = await Api.post(
      `/paypal/Onboarding/ManagedAccount/Corporation/${customerRecord.brandId}/${customerRecord.id}`,
      postData
    );

    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred');
      setLoading(false);
      return;
    }

    let response = apiResponse.response;

    if (!response.accountId) {
      setCorporationErrorForm(corporationFormData);
      setCorporationSubmissionError(response.formattedErrors);
    } else {
      getCustomerRecord();
    }

    setLoading(false);
  };

  const corporationFormStatusCallback = (formStatus, componentData) => {
    getCorporationFormStatus(formStatus);
    setCorporationFormData(componentData);
  };

  const submitNonprofitForm = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (customerRecord.brandId <= 0 || customerRecord.id <= 0) {
      setLoading(false);
      return false;
    }

    const postData = JSON.stringify(nonprofitFormData);
    const apiResponse = await Api.post(
      `/paypal/Onboarding/ManagedAccount/Nonprofit/${customerRecord.brandId}/${customerRecord.id}`,
      postData
    );

    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred');
      setLoading(false);
      return;
    }

    let response = apiResponse.response;

    if (!response.accountId) {
      setNonprofitErrorForm(nonprofitFormData);
      setNonprofitSubmissionError(response.formattedErrors);
    } else {
      getCustomerRecord();
    }

    setLoading(false);
  };

  const nonprofitFormStatusCallback = (formStatus, componentData) => {
    getNonprofitFormStatus(formStatus);
    setNonprofitFormData(componentData);
  };

  const submitProprietorshipForm = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (customerRecord.brandId <= 0 || customerRecord.id <= 0) {
      setLoading(false);
      return false;
    }

    const postData = JSON.stringify(proprietorshipFormData);
    const apiResponse = await Api.post(
      `/paypal/Onboarding/ManagedAccount/Proprietorship/${customerRecord.brandId}/${customerRecord.id}`,
      postData
    );

    if (apiResponse.status.statusCode !== 200) {
      console.error('api error occurred');
      setLoading(false);
      return;
    }

    let response = apiResponse.response;

    if (!response.accountId) {
      setProprietorshipErrorForm(proprietorshipFormData);
      setProprietorshipSubmissionError(response.formattedErrors);
    } else {
      getCustomerRecord();
    }

    setLoading(false);
  };

  const proprietorshipFormStatusCallback = (formStatus, componentData) => {
    getProprietorshipFormStatus(formStatus);
    setProprietorshipFormData(componentData);
  };

  return {
    loading,
    loadingCustomer,
    customerRecord,
    hasPayPalIds,
    currentSelectValue,
    addManagedAccountFlag,
    payPalIdSelection,
    addManagedAccount,
    updateSelect,
    showProcessView,
    governmentFormStatus,
    governmentErrorForm,
    governmentSubmissionError,
    governmentFormStatusCallback,
    submitGovernmentForm,
    corporationFormStatus,
    corporationErrorForm,
    corporationSubmissionError,
    corporationFormStatusCallback,
    submitCorporationForm,
    nonprofitFormStatus,
    nonprofitErrorForm,
    nonprofitSubmissionError,
    nonprofitFormStatusCallback,
    submitNonprofitForm,
    proprietorshipFormStatus,
    proprietorshipErrorForm,
    proprietorshipSubmissionError,
    proprietorshipFormStatusCallback,
    submitProprietorshipForm
  };
};

export default usePayPal;
